
window.addEventListener('load', () => {

	const input_panels = parseInt(document.querySelector("#panels").value);
	const input_panel_length = parseInt(document.querySelector("#panel_length").value);
	const input_cut_length = parseInt(document.querySelector("#cut_length").value);
	const input_even_length = parseInt(document.querySelector("#even_length").value);
	const input_post_height = parseInt(document.querySelector("#post_height").value);
	const input_gap_size = parseInt(document.querySelector("#gap_size").value);
	const input_cut_style = document.querySelector("#cut_style").value;

	window.requestAnimationFrame(() => draw(
		input_panels,
		input_panel_length,
		input_cut_length,
		input_even_length,
		input_post_height,
		input_gap_size,
		input_cut_style
	));

	const slider = document.querySelector('#count');

	slider.addEventListener(
		'click',
		_ => requestAnimationFrame(
			_ => {
				draw(
					parseInt(document.querySelector("#panels").value),
					parseInt(document.querySelector("#panel_length").value),
					parseInt(document.querySelector("#cut_length").value),
					parseInt(document.querySelector("#even_length").value),
					parseInt(document.querySelector("#post_height").value),
					parseInt(document.querySelector("#gap_size").value),
					document.querySelector("#cut_style").value
				)
			}
		)
	);
})

/**
 * Get the image texture.
 *
 * @param {string} id element id
 * @returns Element
 */
function getTexture(id, flip = false) {
	const img = document.getElementById(id);
	const canvas = document.getElementById('scratch_patch')
	const ctx = canvas.getContext('2d')
	const width = 300;
	const height = 300;
	canvas.width = width;
	canvas.height = height;
	ctx.translate(width / 2, height / 2);
	ctx.rotate(Math.PI / 2);
	ctx.translate(0,0);
	ctx.drawImage(img, -(width/2), -(height/ 2));
	const image = new Image()
	image.src = canvas.toDataURL()

	if (!flip) {
		return img;
	}


	return image;
}

/**
 * Determine the total number of slats at a given height.
 *
 * @param {int} blade The height of a blade
 * @param {int} gap the space 9mm / 20mm
 * @param {int} height total height in mm
 * @returns float
 */
const slatsRequired = (blade, gap, height) => {
	return parseInt(height) / (parseInt(blade) + parseInt(gap));
}

/**
 *
 * @param {CanvasRenderingContext2D} ctx
 * @param {int} startX
 * @param {int} startY
 * @param {int} blades
 * @param {Object} blade the blade configs
 */
function drawBladesHorizontal(ctx, startX, startY, blades, blade = { height, width, gap, texture }) {

	let flip = false;
	let flipped = getTexture(blade.texture, true)
	let normal = getTexture(blade.texture, false)
	for (let amount = 0; amount < blades; amount++) {

		drawBlade(
			ctx, /** context */
			startX, /** x */
			startY + ((blade.height + blade.gap) * amount), /** y */
			blade.width, /** width */
			blade.height, /** height */
			flip ? flipped : normal
			// blade.texture
		)
		flip = !flip
	}
}

/**
 *
 * @param {CanvasRenderingContext2D} ctx
 * @param {int} startX
 * @param {int} startY
 * @param {int} blades
 * @param {Object} blade the blade configs
 */
function drawBladesVertical(ctx, startX, startY, blades, blade = { height, width, gap, texture }) {
	const width = blade.height;

	for (let amount = 0; amount < blades; amount++) {

		drawBlade(
			ctx, /** context */
			startX + ((width + blade.gap) * amount), /** x */
			startY, /** y */
			blade.height, /** width */
			blade.width, /** height */
			blade.texture
		)
	}
}

/**
 *
 * @param {CanvasRenderingContext2D} ctx
 * @param {int} x
 * @param {int} y
 * @param {int} width
 * @param {int} height
 * @param {Element} texture
 */
function drawBlade(ctx, x, y, width, height, texture) {
	ctx.fillStyle = ctx.createPattern(texture, 'repeat');
	ctx.strokeStyle = 'black';
	ctx.globalAlpha = 0.95
	ctx.lineWidth = 0.6
	ctx.fillRect(x, y, width, height);
	ctx.strokeRect(x, y, width, height);
	ctx.globalAlpha = 1
}

/**
 *
 * @param {CanvasRenderingContext2D} ctx
 * @param {int} x
 * @param {int} y
 * @param {int} width
 * @param {int} height
 * @param {*} texture
 */
function drawPost(ctx, x, y, width, height, texture) {
	ctx.fillStyle = ctx.createPattern(texture, 'repeat');
	ctx.strokeStyle = 'black';
	ctx.lineWidth = 0.6
	ctx.fillRect(x, y, width, height);
	ctx.strokeRect(x, y, width, height);

	drawPostTop(ctx, x, y + 1, width, texture)
}

function drawFSection(ctx, x, y, width, height, texture) {
	ctx.fillStyle = ctx.createPattern(texture, 'repeat');
	ctx.strokeStyle = 'black';
	ctx.lineWidth = 0.6
	ctx.fillRect(x, y, width, height);
	ctx.strokeRect(x, y, width, height);

	ctx.fillStyle = 'rgba(0, 0, 0, 0.2)';
	ctx.strokeStyle = 'black';
	ctx.lineWidth = 0.2
	ctx.fillRect(x, y, width, height);
	ctx.strokeRect(x, y, width, height);
}

/**
 *
 * @param {CanvasRenderingContext2D} ctx
 * @param {*} x
 * @param {*} y
 * @param {*} postX
 * @param {*} postY
 * @param {*} postWidth
 * @param {*} postHeight
 * @param {*} texture
 */
function drawPostTop(ctx, postX, postY, postWidth, texture) {
	const angleY = 8;
	const rectHeight = 5;
	const dwell = 1;
	ctx.fillStyle = ctx.createPattern(texture, 'repeat');
	ctx.strokeStyle = 'black';
	ctx.lineWidth = 0.6
	ctx.beginPath();
	// start
	ctx.moveTo(postX, postY);
	// vertical
	ctx.lineTo(postX, postY - rectHeight);
	// angle
	ctx.lineTo(postX + (postWidth / 2) - dwell, postY - angleY);
	ctx.lineTo(postX + (postWidth / 2) + dwell, postY - angleY);
	// angle down, vertical start
	ctx.lineTo(postX + postWidth, postY - rectHeight);
	// vertical
	ctx.lineTo(postX + postWidth, postY)
	// ctx.lineTo(0, 90);
	ctx.closePath();
	ctx.fill();
	ctx.stroke();
	// rectangle line
	ctx.strokeRect(postX, postY - rectHeight, postWidth, rectHeight);
}

function scale(canvas, ctx, sizeW = 1000, sizeH = 300) {
	// Set display size (css pixels).
	// const sizeH = 300;
	// const sizeW = 600;

	// ctx = u.getContext("2d");
	canvas.style.height = `${sizeH}px`;
	canvas.style.width = `${sizeW}px`;

	// ctx.width = slider_scale.value
	// widthLess40px = ctx.width - 40;
	// l = widthLess40px / sizeW;
	// m = 90 * l;
	// ctx.height = m + 140;

	// Set actual size in memory (scaled to account for extra pixel density).
	const scale = window.devicePixelRatio; // Change to 1 on retina screens to see blurry canvas.
	canvas.height = Math.floor(sizeH * scale);
	canvas.width = Math.floor(sizeW * scale);

	// Normalize coordinate system to use CSS pixels.
	ctx.scale(scale, scale);

	return scale;
}


// should get the pixels based on the mm sent into it for a dpi of size

function mmToPixels(mm, DPI = 96, pixelRatio = window.devicePixelRatio) {
	// (mm * DPI) / 25.4
	/**
	 * @todo determine the exact sizing to work on.
	 * This is because having it set to a 20mm gap makes the sizing stupid.
	 * might need to make it that it works to a set pixel ratio
	 * that way a 9mm gap looks relative to a 90mm blade
	 *
	 * looks like the printer uses 203 for it's DPI
	 */
	return Math.round(((parseInt(mm) / 25.4) * parseInt(DPI)) / pixelRatio);
}

/**
 * this should draw the f-section and pushloc frames.
 * it would overlap the lengths of the blades.
 *
 */
function draw(input_panels = null, input_panel_length = null, input_cut_length = null, input_even_length = null, input_post_height = null, input_gap_size = null, input_cut_style = null) {
	// horizontal slats
	const scaleConst = 8;
	const canvas = document.querySelector('#canvas')
	// var ctx = canvas.getContext('2d');
	// const scaleConstant = scale(canvas, ctx, totalRun(panelLength, panels, postWidth));
	// const scaleConstant = 1;
	// canvas.style.opacity = 0.6
	const plankHeight = 90 / scaleConst;// mmToPixels(90 / scaleConst);

	/**
	 * if 2 pixels is same as 9mm
	 * 5 is the 20mm
	 */
	const GAP_PRIVATE = 0;  //mmToPixels(0);
	const GAP_9MM = 9 / scaleConst;// mmToPixels(9 / scaleConst);
	const GAP_20MM = 20 / scaleConst; //mmToPixels(20 / scaleConst);

	const plankSpace = input_gap_size / scaleConst;
	const postWidth = 90 / scaleConst;  //mmToPixels(90 / scaleConst);
	// const postHeight = (canvas.height / scaleConstant) - 20;
	const postHeight = input_post_height / scaleConst; // mmToPixels(100);
	const panelLength = input_panel_length / scaleConst; // mmToPixels(100);
	const panelsPerRun = input_panels// parseInt((totalLength / scaleConst)) / parseInt(panelLength) + 1;

	// slider.setAttribute('max', (canvas.height / (plankHeight + plankSpace)) + 1)

	const posts = panelsPerRun + 1 //Math.ceil(panelsPerRun);
	const panels = panelsPerRun;

	function totalRun(length, panels, postW, cut_length, cut_style) {
		if (cut_style === 'even') {
			return ((input_even_length / scaleConst) * (panels));
		} else if (cut_style == 'both') {
			var newPanelLength = length * (panels - 2);
			newPanelLength += cut_length * 2;

			return newPanelLength - postW;
		} else {
			var newPanelLength = length * (panels - 1);
			newPanelLength += cut_length;
			return newPanelLength - postW;
		}
	}

	const completeFenceLength = totalRun(panelLength, panels, postWidth, input_cut_length / scaleConst, input_cut_style);
	var ctx = canvas.getContext('2d');
	const scaleConstant = scale(canvas, ctx, completeFenceLength + 50, (postHeight % 2700 + 50));

	// translate to center the thing and offset to the side
	ctx.translate(25, 25)

	drawBladesHorizontal(
		ctx,
		postWidth,
		11,
		slatsRequired(plankHeight, plankSpace, postHeight - 10),
		{
			height: plankHeight,
			gap: plankSpace,
			width: completeFenceLength,
			texture: 'texture_b',
		})

	drawPosts(ctx, posts, postWidth, postHeight, panelLength, input_cut_length / scaleConst, input_cut_style, input_even_length / scaleConst)
}


function drawPosts(ctx, posts, postWidth, postHeight, panelLength, cut_length, cut_style, input_even_length) {
	let postTexture = getTexture('texture_a')
	if (cut_style === 'even') {

		for (let index = 0; index < posts; index++) {
			const postX = (input_even_length) * index;
			drawPost(ctx, postX, 10, postWidth, postHeight, postTexture);
			if (index != 0 || index == posts - 1) {
				drawFSection(ctx, postX - 3, 11, 3, postHeight - 3, postTexture)
			}

			if (index != posts - 1) {
				drawFSection(ctx, postX + 10, 11, 3, postHeight - 3, postTexture)
			}
		}
	} else if (cut_style === 'last') {

		var lastX = 0;
		posts -= 1

		for (let index = 0; index < posts; index++) {
			const postX = lastX = (panelLength) * index;
			drawPost(ctx, postX, 10, postWidth, postHeight, postTexture);
			if (index != 0) {
				drawFSection(ctx, postX - 3, 11, 3, postHeight - 3, postTexture)
			}
			drawFSection(ctx, postX + 10, 11, 3, postHeight - 3, postTexture)
		}
		drawFSection(ctx, lastX + cut_length - 3, 11, 3, postHeight - 3, postTexture)
		drawPost(ctx, lastX + cut_length, 10, postWidth, postHeight, postTexture);
	} else {

		var lastX = 0;
		posts -= 1
		drawPost(ctx, 0, 10, postWidth, postHeight, postTexture)
		drawPost(ctx, cut_length, 10, postWidth, postHeight, postTexture)

		drawFSection(ctx, 0 + 10, 11, 3, postHeight - 3, postTexture)
		drawFSection(ctx, cut_length + 10, 11, 3, postHeight - 3, postTexture)
		drawFSection(ctx, cut_length - 3, 11, 3, postHeight - 3, postTexture)

		for (let index = 1; index < posts; index++) {
			const postX = lastX = cut_length + (panelLength * index);

			drawPost(ctx, postX, 10, postWidth, postHeight, postTexture)
			if (index != 0 || index == posts - 1) {
				drawFSection(ctx, postX - 3, 11, 3, postHeight - 3, postTexture)
			}

			if (index != posts - 1) {
				drawFSection(ctx, postX + 10, 11, 3, postHeight - 3, postTexture)
			}
		}

		drawFSection(ctx, (lastX - panelLength) + cut_length - 3, 11, 3, postHeight - 3, postTexture)
		drawPost(ctx, (lastX - panelLength) + cut_length, 10, postWidth, postHeight, postTexture);
	}
	// draws posts at the set points based on their size and the total length of the panels.
}
